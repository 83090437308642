import React from "react";
import "./style.css";

export default function ContactForm() {
    const submitForm = () => {
        console.log('form submitted')
    }
    return (
        <h3 className="newClients contactForm">I am currently accepting new clients and/or looking for new opportunities in the field of web development</h3>
        // <form className="contactForm">
        //     <div>
        //         <input type="text" id="name" required />
        //         <label for="name">Enter your name</label>
        //     </div>
        //     <div>
        //         <input type="text" id="email" required />
        //         <label for="email">Email address</label>
        //     </div>
        //     <div>
        //         <textarea id="message" required></textarea>
        //         <label for="message">What do you want to say?</label>
        //     </div>
        //     <button class="cta" type="submit" onClick={()=> submitForm()}>Get in touch</button>
        // </form>
    )
}