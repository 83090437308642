import React from "react";
import "./style.css";

import Intro from "./intro";
import WhatIDo from "./whatIDo";
import Works from "./works";

export default function Home() {
    return (
        <div className="home content">
            <Intro />
            {/* <Works />
            <WhatIDo /> */}
        </div>
    )
}