import React from "react";
import './style.css';

import ContactInfo from "../ContactInfo";
import ContactForm from "../ContactForm";

export default function Footer() {
    return (
        <footer>
            <div id="contact" className="clearfix">
                <ContactInfo />
                <ContactForm />
            </div>
            <div className="legal">
                <div>
                    <h3>John<br />Humbracht</h3>
                </div>
                <div>
                    {/* <a href="#">Privacy Policy &amp; Cookies</a> */}
                </div>
                <div>
                    <p>&copy;2024<br />All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
}