import React from "react";

export function EmailIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" className="icon" x="0px" y="0px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
                        <g>
                            <path d="M486.4,59.733H25.6c-14.138,0-25.6,11.461-25.6,25.6v341.333c0,14.138,11.461,25.6,25.6,25.6h460.8     c14.138,0,25.6-11.461,25.6-25.6V85.333C512,71.195,500.539,59.733,486.4,59.733z M494.933,426.667     c0,4.713-3.82,8.533-8.533,8.533H25.6c-4.713,0-8.533-3.82-8.533-8.533V85.333c0-4.713,3.82-8.533,8.533-8.533h460.8     c4.713,0,8.533,3.82,8.533,8.533V426.667z"/>
                            <path d="M470.076,93.898c-2.255-0.197-4.496,0.51-6.229,1.966L266.982,261.239c-6.349,5.337-15.616,5.337-21.965,0L48.154,95.863     c-2.335-1.96-5.539-2.526-8.404-1.484c-2.865,1.042-4.957,3.534-5.487,6.537s0.582,6.06,2.917,8.02l196.864,165.367     c12.688,10.683,31.224,10.683,43.913,0L474.82,108.937c1.734-1.455,2.818-3.539,3.015-5.794c0.197-2.255-0.51-4.496-1.966-6.229     C474.415,95.179,472.331,94.095,470.076,93.898z"/>
                            <path d="M164.124,273.13c-3.021-0.674-6.169,0.34-8.229,2.65l-119.467,128c-2.162,2.214-2.956,5.426-2.074,8.392     c0.882,2.967,3.301,5.223,6.321,5.897c3.021,0.674,6.169-0.34,8.229-2.65l119.467-128c2.162-2.214,2.956-5.426,2.074-8.392     C169.563,276.061,167.145,273.804,164.124,273.13z"/>
                            <path d="M356.105,275.78c-2.059-2.31-5.208-3.324-8.229-2.65c-3.021,0.674-5.439,2.931-6.321,5.897     c-0.882,2.967-0.088,6.178,2.074,8.392l119.467,128c3.24,3.318,8.536,3.442,11.927,0.278c3.391-3.164,3.635-8.456,0.549-11.918     L356.105,275.78z"/>
                        </g>
                    </svg>
    )
}

export function PhoneIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512.034 512.034" enableBackground="new 0 0 512.034 512.034" xmlSpace="preserve" className="icon">
                        <g transform="translate(-1)">
                            <path d="M12.401,160.324c3.049,11.234,13.294,18.997,24.934,18.893c1.678,0.001,3.353-0.159,5.001-0.478l51.712-9.916     l39.782-8.73c13.094-2.354,22.1-14.484,20.565-27.699l-4.855-37.231c-0.606-4.634,10.018-9.771,19.925-11.264     c57.431-9.292,115.97-9.444,173.448-0.452c15.795,2.722,19.285,5.257,20.036,6.204c1.587,3.919,1.819,8.256,0.657,12.322     l-3.482,24.747c-1.958,8.001-0.568,16.454,3.849,23.407c6.185,6.535,14.54,10.591,23.501,11.409     c5.973,1.143,10.573,2.295,15.172,3.413c4.881,1.212,9.754,2.423,16.102,3.635l46.259,8.926     c8.045,2.372,16.664,1.871,24.38-1.417c8.121-5.233,13.381-13.914,14.259-23.535l0.282-1.237     c0.896-3.942,2.082-7.851,3.268-11.759c3.377-9.375,5.198-19.239,5.393-29.201c-1.377-17.556-9.47-33.905-22.596-45.645     c-59.085-57.992-161.28-64.7-232.96-64.7C93.391,0.017,31.038,47.121,7.545,86.639c-6.452,10.932-8.224,24.003-4.915,36.258     L12.401,160.324z M22.206,95.377c21.231-35.729,79.206-78.293,234.829-78.293c68.719,0,166.4,6.204,221.013,59.81     c9.928,8.675,16.167,20.815,17.442,33.937c-0.298,8.127-1.876,16.156-4.676,23.791c-1.289,4.267-2.56,8.576-3.567,12.902     l-0.299,1.297c-1.425,6.349-2.56,10.624-5.487,12.151c-4.347,1.184-8.942,1.101-13.244-0.239l-46.225-8.823     c-5.973-1.143-10.59-2.295-15.189-3.413c-4.881-1.212-9.754-2.423-16.085-3.635c-4.087-0.785-10.94-2.091-13.116-4.966     c-1.371-3.412-1.563-7.184-0.546-10.718l3.49-24.747c2.037-8.322,0.751-17.11-3.584-24.499     c-5.513-7.851-18.219-10.991-31.113-13.218c-59.284-9.303-119.667-9.171-178.91,0.393c-18.671,2.833-36.514,13.312-34.295,30.353     l4.864,37.222c0.352,4.344-2.755,8.205-7.074,8.789l-39.799,8.644l-51.507,9.865c-4.452,1.005-8.923-1.603-10.24-5.973     l-9.737-37.376C16.999,110.784,18.1,102.401,22.206,95.377z"/>
                            <path d="M511.498,389.572c-0.085-0.222-0.171-0.435-0.265-0.631c-0.797-2.121-1.867-4.129-3.183-5.973l-108.467-147.2     c-6.45-8.715-16.643-13.864-27.486-13.884h-22.187l-7.543-60.356c-0.335-9.182-7.879-16.45-17.067-16.444h-25.6     c-9.426,0-17.067,7.641-17.067,17.067v68.267c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533v-68.267h25.6     c-0.003,0.354,0.02,0.708,0.068,1.058l8.533,68.267c0.534,4.27,4.162,7.474,8.465,7.475h29.73     c5.419,0.009,10.515,2.582,13.739,6.938l101.777,138.129h-68.446c-4.713,0-8.533,3.821-8.533,8.533     c0,4.713,3.82,8.533,8.533,8.533h76.8v68.267c0,14.138-11.461,25.6-25.6,25.6H43.701c-14.138,0-25.6-11.461-25.6-25.6v-68.267     h358.4c4.713,0,8.533-3.821,8.533-8.533c0-4.713-3.82-8.533-8.533-8.533H26.455l101.777-138.129     c3.224-4.356,8.319-6.929,13.739-6.938h29.73c4.303-0.001,7.932-3.206,8.465-7.475l8.533-68.267     c0.048-0.351,0.071-0.704,0.068-1.058h25.6v68.267c0,4.713,3.82,8.533,8.533,8.533h34.133c4.713,0,8.533-3.82,8.533-8.533     s-3.82-8.533-8.533-8.533h-25.6v-59.733c0-9.426-7.641-17.067-17.067-17.067h-25.6c-9.188-0.006-16.731,7.262-17.067,16.444     l-7.535,60.356h-22.187c-10.83,0.03-21.009,5.178-27.452,13.884L6.018,382.976c-1.323,1.84-2.393,3.849-3.183,5.973     c-0.094,0.188-0.179,0.401-0.265,0.614c-0.999,2.756-1.518,5.662-1.536,8.593v71.194c0.028,23.552,19.114,42.638,42.667,42.667     h426.667c23.552-0.028,42.638-19.114,42.667-42.667v-71.194C513.017,395.229,512.498,392.325,511.498,389.572z"/>
                            <path d="M200.8,285.773c7.859-4.194,16.254-7.298,24.951-9.225c20.602-4.599,41.965-4.599,62.566,0     c2.975,0.707,6.101-0.233,8.194-2.463c2.093-2.23,2.832-5.409,1.938-8.334c-0.894-2.924-3.285-5.147-6.267-5.826     c-23.146-5.18-47.151-5.18-70.298,0c-10.217,2.281-20.076,5.944-29.303,10.889c-8.089,4.234-15.218,10.09-20.941,17.203     c-11.313,13.599-11.313,33.334,0,46.933c5.723,7.113,12.852,12.97,20.941,17.203c9.228,4.944,19.086,8.607,29.303,10.889     c23.143,5.211,47.155,5.211,70.298,0c10.217-2.281,20.076-5.944,29.303-10.889c8.089-4.234,15.218-10.09,20.941-17.203     c11.313-13.599,11.313-33.334,0-46.933c-1.832-2.491-4.869-3.795-7.937-3.41c-3.068,0.385-5.687,2.4-6.847,5.266     c-1.16,2.866-0.678,6.136,1.258,8.546c6.575,7.469,6.575,18.66,0,26.129c-4.306,5.225-9.624,9.527-15.633,12.646     c-7.859,4.194-16.254,7.298-24.951,9.225c-20.602,4.599-41.965,4.599-62.566,0c-8.695-1.925-17.087-5.029-24.943-9.225     c-6.013-3.118-11.334-7.419-15.642-12.646c-6.575-7.469-6.575-18.66,0-26.129C189.473,293.194,194.79,288.893,200.8,285.773z"/>
                        </g>
                    </svg>
    )
}

export function AddressIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 511.998 511.998" enableBackground="new 0 0 511.998 511.998" xmlSpace="preserve" className="icon">
                        <g transform="translate(0 -1)">
                            <path d="M167.974,351.473c21.086,29.73,42.138,56.32,56.09,73.446c2.031,2.492,3.934,4.804,5.777,7.031h-42.103     c-4.713,0-8.533,3.82-8.533,8.533s3.82,8.533,8.533,8.533H324.27c4.713,0,8.533-3.82,8.533-8.533s-3.82-8.533-8.533-8.533     h-42.103c1.843-2.227,3.746-4.54,5.786-7.031c13.943-17.067,34.987-43.716,56.081-73.446     c20.929-28.905,39.767-59.267,56.371-90.854c17.425-34.219,26.266-62.626,26.266-84.437     c0.058-45.321-17.926-88.801-49.98-120.841c-2.141-2.22-5.313-3.112-8.297-2.333c-2.984,0.779-5.316,3.107-6.099,6.09     c-0.783,2.983,0.104,6.156,2.321,8.3c28.852,28.844,45.039,67.985,44.988,108.783c0,19.098-8.209,44.902-24.405,76.689     c-16.229,30.855-34.64,60.513-55.091,88.747c-20.804,29.346-41.6,55.637-55.381,72.533c-4.582,5.615-8.704,10.581-12.22,14.78     c-1.641,1.874-4.011,2.948-6.502,2.948c-2.491,0-4.861-1.075-6.502-2.948c-3.516-4.198-7.637-9.165-12.22-14.78     c-13.781-16.887-34.577-43.179-55.381-72.533c-20.451-28.234-38.862-57.892-55.091-88.747     c-16.213-31.787-24.405-57.591-24.405-76.689c-0.005-61.809,36.959-117.627,93.867-141.747     c38.238-16.034,81.314-16.034,119.552,0c2.808,1.19,6.037,0.793,8.472-1.044c2.435-1.836,3.705-4.832,3.332-7.859     c-0.373-3.027-2.332-5.625-5.14-6.815c-86.898-36.652-187.056,4.062-223.735,90.948c-8.895,21.046-13.457,43.669-13.414,66.517     c0,21.811,8.841,50.219,26.266,84.48C128.208,292.234,147.047,322.582,167.974,351.473z"/>
                            <path d="M256.003,99.151c4.713,0,8.533-3.82,8.533-8.533s-3.82-8.533-8.533-8.533c-40.71-0.001-75.752,28.757-83.695,68.685     c-7.943,39.928,13.426,79.906,51.037,95.486c37.611,15.58,80.99,2.421,103.608-31.428     c22.618-33.849,18.175-78.962-10.611-107.749c-2.143-2.218-5.315-3.108-8.299-2.327c-2.983,0.781-5.313,3.111-6.094,6.094     c-0.781,2.984,0.109,6.156,2.327,8.299c23.03,23.029,26.585,59.118,8.492,86.198c-18.093,27.08-52.796,37.608-82.885,25.146     c-30.089-12.462-47.185-44.444-40.833-76.387S223.435,99.152,256.003,99.151z"/>
                            <path d="M508.786,470.957l-52.48-93.867c-4.745-8.34-13.624-13.466-23.219-13.406H372.27c-4.713,0-8.533,3.82-8.533,8.533     s3.82,8.533,8.533,8.533h60.817c3.416-0.066,6.603,1.715,8.337,4.659l52.463,93.867c1.429,2.442,1.399,5.471-0.077,7.885     c-1.743,2.88-4.895,4.605-8.26,4.523H26.457c-3.362,0.08-6.51-1.646-8.252-4.523c-1.476-2.414-1.505-5.443-0.077-7.885     l52.463-93.867c1.731-2.943,4.915-4.724,8.328-4.659h60.817c4.713,0,8.533-3.82,8.533-8.533s-3.82-8.533-8.533-8.533H78.92     c-9.6-0.063-18.486,5.063-23.236,13.406L3.212,470.957c-4.375,7.728-4.274,17.208,0.265,24.841     c4.782,8.128,13.551,13.071,22.98,12.954H485.55c9.426,0.114,18.192-4.828,22.972-12.954     C513.061,488.164,513.162,478.685,508.786,470.957z"/>
                            <path d="M418.137,406.351h-34.133c-1.685,0.003-3.332,0.501-4.736,1.434l-25.6,17.067c-3.921,2.616-4.979,7.915-2.364,11.836     c2.616,3.921,7.915,4.979,11.836,2.364l23.424-15.633h31.573c4.713,0,8.533-3.821,8.533-8.533     C426.67,410.171,422.85,406.351,418.137,406.351z"/>
                            <path d="M366.937,466.084c0,4.713,3.82,8.533,8.533,8.533h68.267c4.713,0,8.533-3.82,8.533-8.533s-3.82-8.533-8.533-8.533H375.47     C370.757,457.551,366.937,461.371,366.937,466.084z"/>
                            <path d="M153.603,440.484c3.762,0.005,7.083-2.455,8.176-6.055c1.093-3.6-0.301-7.491-3.431-9.578l-25.6-17.067     c-1.406-0.934-3.057-1.433-4.745-1.434H93.87c-4.713,0-8.533,3.821-8.533,8.533c0,4.713,3.821,8.533,8.533,8.533h31.573     l23.45,15.633C150.288,439.981,151.927,440.48,153.603,440.484z"/>
                            <path d="M68.27,457.551c-4.713,0-8.533,3.82-8.533,8.533s3.82,8.533,8.533,8.533h68.267c4.713,0,8.533-3.82,8.533-8.533     s-3.82-8.533-8.533-8.533H68.27z"/>
                        </g>
                    </svg>
    )
}

export function GithubIcon() {
    return (
        <svg className="icon" height="32" viewBox="0 0 16 16" version="1.1" width="32" aria-hidden="true"><path fill-rule="evenodd" d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"></path></svg>
    )
}

export function LinkedinIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" className="icon" x="0px" y="0px" viewBox="0 0 382 382" enableBackground="new 0 0 382 382" xmlSpace="preserve">
                        <path d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889  C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056  H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806  c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1  s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73  c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079  c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426  c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472  L341.91,330.654L341.91,330.654z"/>
                    </svg>
    )
}