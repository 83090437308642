import React from "react";
import "./style.css";
import {EmailIcon, PhoneIcon, AddressIcon, GithubIcon, LinkedinIcon} from "../ContactIcons";
// import EmailIcon from "../ContactIcons/emailIcon";

export default function ContactInfo() {
    let emailIcon = () => <EmailIcon />;
    let phoneIcon = () => <PhoneIcon />;
    let addressIcon = () => <AddressIcon />;
    let githubIcon = () => <GithubIcon />;
    let linkedinIcon = () => <LinkedinIcon />;
    return (
        <div className="contactText">
            <h2>Contact Info</h2>
            <ul>
                <li>
                    {emailIcon()}
                    Email: <a href="mailto:jhumbrac@gmail.com">jhumbrac@gmail.com</a>
                </li>
                <li>
                    {phoneIcon()}
                    <a href="tel:+16154963963">(615) 496 - 3963</a>
                </li>
                <li className="addressIcon">
                    {addressIcon()}
                    <address><span>Nashville, TN</span></address>
                </li>
                <li>
                    {githubIcon()}
                    Github: <a href="https://github.com/jhumbrac">jhumbrac</a>
                </li>
                <li>
                    {linkedinIcon()}
                    <a href="https://www.linkedin.com/in/john-humbracht-b388624/">LinkedIn</a>
                </li>
                <li>
                    <a href="JohnHumbrachtResume.pdf" target="_blank">See Resume</a>
                </li>
            </ul>
        </div>
    )
}