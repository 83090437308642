import React from "react";
import Typical from 'react-typical';
import Darrow from "../../assets/Darrow";
import { Link } from "react-router-dom";

export default function Intro() {
    return (
        <section id="intro">
            <div className="welcome">
                <h1><span className="iAm">I am</span> John<span className="humbracht">Humbracht</span></h1>
                <hr />
                <h2>I Specialize In:</h2>
                <Typical
                    steps={[
                        'Web Development', 1000,    
                        'Interface Design', 1000,
                        'Search Optimization', 1200,
                        'User Experience', 1000,
                        'Branding', 1000,
                        'Custom Websites', 1000,
                        'Responsive Design', 1000,
                        'Progressive Web Apps', 1000,
                        'Website Optimization', 1000,
                        'ADA Compliance', 1000,
                        'Accessibility', 1000
                    ]}
                    loop={Infinity}
                    wrapper="h3"
                />
            </div>
            <Darrow />
        </section>
    )
}